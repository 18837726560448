<template>
  <v-app>
    <v-app-bar color="primary" flat height="110" tile>
      <v-spacer></v-spacer>
      <a href="#" class="text-center pa-5">
        <img src="/media/logos/logo_light_155x30.png" class="max-h-70px" alt />
      </a>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-content class="mb-12">
      <v-container class="mb-12">
        <v-container class="mb-12">
          <v-row class="my-8">
            <v-col cols="12">
              <v-row class="justify-center">
                <v-col cols="12" md="12">
                  <vue-inline-alert
                    v-if="message || secondaryMessage"
                    :message="message"
                    :secondaryMessage="secondaryMessage"
                    messageType="success"
                  />
                  <div v-if="getActivateErrorMessage" class="my-8">
                    <div class="mb-8">
                      <inline-svg
                        class="svg-icon"
                        src="/media/placeholders/no_devices.svg"
                        v-if="getActivateErrorMessage"
                      />
                    </div>
                    <vue-inline-alert
                      v-if="getActivateErrorMessage"
                      :message="getActivateErrorMessage"
                      messageType="error"
                    />
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col v-if="mobile === 'unknown'" md="6" cols="12">
              <v-card outlined>
                <v-card-title>
                  <div>
                    <div class="font-size-h3 bold-text">Access our portal</div>
                    <div class="dark-grey-text body-1">
                      Manage team, apps, releases and more
                    </div>
                  </div>
                </v-card-title>
                <v-card-text>
                  <v-img
                    :src="'https://testapp.io/images/TestApp.io---Upload-Release.svg'"
                  ></v-img>
                </v-card-text>
                <v-card-actions>
                  <v-spacer> </v-spacer>
                  <v-btn
                    x-large
                    href="/"
                    color="primary"
                    class="text-transform-none bold-text font-size-h3 pa-8"
                    >Developer Portal</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col md="6" cols="12">
              <v-card outlined>
                <v-card-title>
                  <div>
                    <div class="font-size-h3 bold-text">Download our app</div>
                    <div class="dark-grey-text body-1">
                      To install your team releases and more
                    </div>
                  </div>
                </v-card-title>
                <v-card-text>
                  <v-img
                    :src="'https://testapp.io/images/TestApp.io---App.svg'"
                  ></v-img>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="mobile === 'unknown' || mobile === 'android'"
                    color="primary"
                    :x-large="$vuetify.breakpoint.mdAndUp"
                    :class="$vuetify.breakpoint.mdAndUp ? 'pa-8' : ''"
                    class="text-transform-none bold-text font-size-h3"
                    @click="openDownloadLinks('playStore')"
                  >
                    <v-icon color="white" v-text="'mdi-android'" left></v-icon>
                    Get our Android
                  </v-btn>
                  <v-btn
                    v-if="mobile === 'unknown' || mobile === 'ios'"
                    color="primary"
                    :x-large="$vuetify.breakpoint.mdAndUp"
                    :class="$vuetify.breakpoint.mdAndUp ? 'pa-8' : ''"
                    class="text-transform-none bold-text font-size-h3"
                    @click="openDownloadLinks('appStore')"
                  >
                    <v-icon color="white" v-text="'mdi-apple'" left></v-icon>
                    Get our iOS
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    </v-content>
    <v-footer>
      <k-t-footer></k-t-footer>
    </v-footer>
    <Modal
      v-model="showAndroidOption"
      @close="showAndroidOption = false"
      :width="$vuetify.breakpoint.smAndDown ? '' : 800"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <template #message>
        <v-row>
          <v-col v-if="mobile === 'unknown' || mobile === 'android'" cols="12">
            <div class="mt-3 font-size-h3 dark-grey-text bold-text">
              Get our app for Android
            </div>
            <div class="mb-5 body-1 dark-grey-text bold-text">
              You can use following android stores to download our app.
              <br />
              If you need help please
              <span
                @click="onContactus"
                class="pointer blue--text text-decoration-underline"
                >contact us</span
              >
            </div>
            <v-row
              :justify="
                $vuetify.breakpoint.smAndDown ? 'center' : 'space-around'
              "
            >
              <v-col cols="12" md="3">
                <a @click="openDownloadLinks('playStore')">
                  <img src="/media/svg/google-play.svg" class="install-img" />
                </a>
              </v-col>
              <v-col cols="12" md="3">
                <a @click="openDownloadLinks('huaweiStore')">
                  <img src="/media/svg/haweiistore.png" class="install-img" />
                </a>
              </v-col>
              <v-col cols="12" md="3">
                <a @click="openDownloadLinks('samsungStore')">
                  <img src="/media/svg/samsungstore.png" class="install-img" />
                </a>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </template>
    </Modal>
    <Modal
      no-py
      :fullscreen="$vuetify.breakpoint.smAndDown"
      v-model="showDownloadModal"
      @close="showDownloadModal = false"
    >
      <template #message>
        <app-download-modal
          :link="selectedLink"
          :title="selectedTitle"
        ></app-download-modal>
      </template>
    </Modal>
  </v-app>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import AppDownloadModal from "@/view/components/Common/AppDownloadModal.vue";
import KTFooter from "@/view/layout/footer/Footer.vue";

export default {
  name: "install",
  components: { KTFooter, AppDownloadModal },
  metaInfo: {
    title: "TestApp.io - Portal ",
    meta: [
      {
        name: "description",
        content: `A platform where developers can easily share their app Android (APK) &amp; iOS (IPA) with their friends, colleagues, testers,... to get their instant feedback!`,
      },
      {
        name: "og:description",
        content: `A platform where developers can easily share their app Android (APK) &amp; iOS (IPA) with their friends, colleagues, testers,... to get their instant feedback!`,
      },
      {
        name: "robots",
        content: `noindex,nofollow`,
      },
      {
        name: "twitter:description",
        content: `A platform where developers can easily share their app Android (APK) &amp; iOS (IPA) with their friends, colleagues, testers,... to get their instant feedback!`,
      },
    ],
  },
  data() {
    return {
      mobile: "",
      message: "",
      secondaryMessage: "",
      showAndroidOption: false,
      showIOSOption: false,
      selectedLink: "",
      selectedTitle: "",
      showDownloadModal: false,
      downloadLinks: {
        playStore: {
          link: "https://play.google.com/store/apps/details?id=testapp.io&hl=en_US&gl=US",
          title: "Google Play Store",
        },
        huaweiStore: {
          link: "https://appgallery.huawei.com/#/app/C103407753",
          title: "Huawei AppGallery",
        },
        samsungStore: {
          link: "https://galaxy.store/testappio",
          title: "Samsung Galaxy Store",
        },
        amazonStore: {
          link: "https://www.amazon.com/gp/product/B095RFPRNR",
          title: "Amazon AppStore",
        },
        appStore: {
          link: "https://apps.apple.com/us/app/testapp-io/id1518972541",
          title: "Apple App Store",
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      getActivateErrorMessage: "getActivateErrorMessage",
    }),
  },
  created() {
    if (this.$route.params.message) {
      this.message = this.$route.params.message;
    }
    this.mobile = this.getMobileOperatingSystem;

    if (this.mobile === "unknown") {
      this.secondaryMessage =
        "To create a release go to developer portal and to install a release download our app.";
    } else {
      this.secondaryMessage =
        "Open or install the app form below button to get started.";
    }
  },
  beforeDestroy() {
    this.setActivateErrorMessage();
  },
  methods: {
    ...mapMutations({
      setActivateErrorMessage: "setActivateErrorMessage",
    }),
    openDownloadLinks(device) {
      this.selectedTitle = this.downloadLinks[device].title;
      this.selectedLink = this.downloadLinks[device].link;
      if (this.mobile !== "unknown") {
        if (this.mobile === "android") {
          window.location.href = `intent://#Intent;scheme=testappio://;package=testapp.io;S.browser_fallback_url=${encodeURIComponent(
            this.selectedLink
          )};end`;
        } else {
          window.open(this.selectedLink, "_blank");
        }
      } else {
        this.showDownloadModal = true;
      }
    },
  },
};
</script>

<style lang="scss">
.v-btn.install {
  width: 195px !important;
  height: 64px !important;
  border-radius: 10px !important;
  span {
    font-size: 19px !important;
    text-transform: capitalize;
    letter-spacing: 0;
  }
}
img.install-img {
  width: 195px !important;
  height: 64px !important;
}
</style>
